import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Seo from "../components/seo";

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <div className="w-full flex flex-col items-center justify-evenly h-screen">
      <div className="lg:h-1/2 flex items-center justify-center z-20">
        <StaticImage
          height={400}
          src="../images/LogoNoBackground.png"
          alt="logo home"
          placeholder="tracedSVG"
          className="z-20 m-12 lg:m-0"
        />
      </div>

      <div className="z-50 px-3 lg:px-0 w-full lg:w-auto h-auto  sm:max-w-sm md:max-w-lg lg:max-w-2xl xl:max-w-full 2xl:max-w-full flex overflow-x-scroll gap-4 xl:gap-0 overscroll-x-contain 2xl:overflow-x-hidden 2xl:overscroll-x-none overflow-y-hidden">
        <Link to="/about" className="relative transform lg:hover:scale-110">
          <StaticImage
            height={238}
            src="../images/hero/1.png"
            alt="logo home"
            placeholder="tracedSVG"
            className="z-0 min-w-lg"
            layout="fixed"
          />
          <div className="h-60  absolute top-0 bottom-0 backdrop-opacity-10 bg-white/40	w-full flex items-center justify-center">
            <p className="desktop-navbar text-3xl">About Bob & Dana</p>
          </div>
        </Link>
        <Link to="/our-art" className="relative transform lg:hover:scale-110">
          <StaticImage
            height={238}
            src="../images/hero/2.png"
            alt="logo home"
            placeholder="tracedSVG"
            className="z-0"
            layout="fixed"
          />
          <div className="h-60  absolute top-0 bottom-0 backdrop-opacity-10 bg-white/40	w-full flex items-center justify-center">
            <p className="desktop-navbar text-3xl">Our Art</p>
          </div>
        </Link>
        <Link to="/projects" className="relative transform lg:hover:scale-110">
          <StaticImage
            height={238}
            src="../images/hero/3.png"
            alt="logo home"
            placeholder="tracedSVG"
            className="z-0"
            layout="fixed"
          />
          <div className="h-60  absolute top-0 bottom-0 backdrop-opacity-10 bg-white/40	w-full flex items-center justify-center">
            <p className="desktop-navbar text-3xl">Projects</p>
          </div>
        </Link>
        <a
          href="https://barefootartes.myshopify.com/"
          className="relative transform lg:hover:scale-110"
        >
          <StaticImage
            height={238}
            src="../images/hero/4.png"
            alt="logo home"
            placeholder="tracedSVG"
            className="z-0"
            layout="fixed"
          />
          <div className="h-60  absolute top-0 bottom-0 backdrop-opacity-10 bg-white/40	w-full flex items-center justify-center">
            <p className="desktop-navbar text-3xl">Art Store</p>
          </div>
        </a>

        <Link to="/contact" className="relative transform lg:hover:scale-110">
          <StaticImage
            height={238}
            src="../images/hero/5.png"
            alt="logo home"
            placeholder="tracedSVG"
            className="z-0"
            layout="fixed"
          />
          <div className="h-60  absolute top-0 bottom-0 backdrop-opacity-10 bg-white/40	w-full flex items-center justify-center">
            <p className="desktop-navbar text-3xl">Say Hi!</p>
          </div>
        </Link>
      </div>
    </div>
  </>
);

export default IndexPage;
